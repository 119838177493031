import { default as _91slug_93BzaHAB5h4MMeta } from "/app/pages/[category]/[slug].vue?macro=true";
import { default as _91slug_93msfiAekumQMeta } from "/app/pages/[slug].vue?macro=true";
import { default as _91slug_931Kp94TPaydMeta } from "/app/pages/artist/[slug].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91slug_93h6ecm9GkHAMeta } from "/app/pages/news/[slug].vue?macro=true";
import { default as artistOJ4Re3xFh7Meta } from "/app/pages/preview/artist.vue?macro=true";
import { default as newslJeNrHnHA0Meta } from "/app/pages/preview/news.vue?macro=true";
import { default as simple_45page1iE2CAJyY0Meta } from "/app/pages/preview/simple-page.vue?macro=true";
export default [
  {
    name: _91slug_93BzaHAB5h4MMeta?.name ?? "category-slug___pl",
    path: _91slug_93BzaHAB5h4MMeta?.path ?? "/:category()/:slug()",
    meta: _91slug_93BzaHAB5h4MMeta || {},
    alias: _91slug_93BzaHAB5h4MMeta?.alias || [],
    redirect: _91slug_93BzaHAB5h4MMeta?.redirect || undefined,
    component: () => import("/app/pages/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BzaHAB5h4MMeta?.name ?? "category-slug___en",
    path: _91slug_93BzaHAB5h4MMeta?.path ?? "/en/:category()/:slug()",
    meta: _91slug_93BzaHAB5h4MMeta || {},
    alias: _91slug_93BzaHAB5h4MMeta?.alias || [],
    redirect: _91slug_93BzaHAB5h4MMeta?.redirect || undefined,
    component: () => import("/app/pages/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93msfiAekumQMeta?.name ?? "slug___pl",
    path: _91slug_93msfiAekumQMeta?.path ?? "/:slug()",
    meta: _91slug_93msfiAekumQMeta || {},
    alias: _91slug_93msfiAekumQMeta?.alias || [],
    redirect: _91slug_93msfiAekumQMeta?.redirect || undefined,
    component: () => import("/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93msfiAekumQMeta?.name ?? "slug___en",
    path: _91slug_93msfiAekumQMeta?.path ?? "/en/:slug()",
    meta: _91slug_93msfiAekumQMeta || {},
    alias: _91slug_93msfiAekumQMeta?.alias || [],
    redirect: _91slug_93msfiAekumQMeta?.redirect || undefined,
    component: () => import("/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931Kp94TPaydMeta?.name ?? "artist-slug___pl",
    path: _91slug_931Kp94TPaydMeta?.path ?? "/artysta/:slug",
    meta: _91slug_931Kp94TPaydMeta || {},
    alias: _91slug_931Kp94TPaydMeta?.alias || [],
    redirect: _91slug_931Kp94TPaydMeta?.redirect || undefined,
    component: () => import("/app/pages/artist/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931Kp94TPaydMeta?.name ?? "artist-slug___en",
    path: _91slug_931Kp94TPaydMeta?.path ?? "/en/artist/:slug",
    meta: _91slug_931Kp94TPaydMeta || {},
    alias: _91slug_931Kp94TPaydMeta?.alias || [],
    redirect: _91slug_931Kp94TPaydMeta?.redirect || undefined,
    component: () => import("/app/pages/artist/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___pl",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93h6ecm9GkHAMeta?.name ?? "news-slug___pl",
    path: _91slug_93h6ecm9GkHAMeta?.path ?? "/news/:slug",
    meta: _91slug_93h6ecm9GkHAMeta || {},
    alias: _91slug_93h6ecm9GkHAMeta?.alias || [],
    redirect: _91slug_93h6ecm9GkHAMeta?.redirect || undefined,
    component: () => import("/app/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93h6ecm9GkHAMeta?.name ?? "news-slug___en",
    path: _91slug_93h6ecm9GkHAMeta?.path ?? "/en/news/:slug()",
    meta: _91slug_93h6ecm9GkHAMeta || {},
    alias: _91slug_93h6ecm9GkHAMeta?.alias || [],
    redirect: _91slug_93h6ecm9GkHAMeta?.redirect || undefined,
    component: () => import("/app/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: artistOJ4Re3xFh7Meta?.name ?? "preview-artist___pl",
    path: artistOJ4Re3xFh7Meta?.path ?? "/podglad/artysta",
    meta: artistOJ4Re3xFh7Meta || {},
    alias: artistOJ4Re3xFh7Meta?.alias || [],
    redirect: artistOJ4Re3xFh7Meta?.redirect || undefined,
    component: () => import("/app/pages/preview/artist.vue").then(m => m.default || m)
  },
  {
    name: artistOJ4Re3xFh7Meta?.name ?? "preview-artist___en",
    path: artistOJ4Re3xFh7Meta?.path ?? "/en/preview/artist",
    meta: artistOJ4Re3xFh7Meta || {},
    alias: artistOJ4Re3xFh7Meta?.alias || [],
    redirect: artistOJ4Re3xFh7Meta?.redirect || undefined,
    component: () => import("/app/pages/preview/artist.vue").then(m => m.default || m)
  },
  {
    name: newslJeNrHnHA0Meta?.name ?? "preview-news___pl",
    path: newslJeNrHnHA0Meta?.path ?? "/podglad/news",
    meta: newslJeNrHnHA0Meta || {},
    alias: newslJeNrHnHA0Meta?.alias || [],
    redirect: newslJeNrHnHA0Meta?.redirect || undefined,
    component: () => import("/app/pages/preview/news.vue").then(m => m.default || m)
  },
  {
    name: newslJeNrHnHA0Meta?.name ?? "preview-news___en",
    path: newslJeNrHnHA0Meta?.path ?? "/en/preview/news",
    meta: newslJeNrHnHA0Meta || {},
    alias: newslJeNrHnHA0Meta?.alias || [],
    redirect: newslJeNrHnHA0Meta?.redirect || undefined,
    component: () => import("/app/pages/preview/news.vue").then(m => m.default || m)
  },
  {
    name: simple_45page1iE2CAJyY0Meta?.name ?? "preview-simple-page___pl",
    path: simple_45page1iE2CAJyY0Meta?.path ?? "/podglad/podstrona",
    meta: simple_45page1iE2CAJyY0Meta || {},
    alias: simple_45page1iE2CAJyY0Meta?.alias || [],
    redirect: simple_45page1iE2CAJyY0Meta?.redirect || undefined,
    component: () => import("/app/pages/preview/simple-page.vue").then(m => m.default || m)
  },
  {
    name: simple_45page1iE2CAJyY0Meta?.name ?? "preview-simple-page___en",
    path: simple_45page1iE2CAJyY0Meta?.path ?? "/en/preview/simple-page",
    meta: simple_45page1iE2CAJyY0Meta || {},
    alias: simple_45page1iE2CAJyY0Meta?.alias || [],
    redirect: simple_45page1iE2CAJyY0Meta?.redirect || undefined,
    component: () => import("/app/pages/preview/simple-page.vue").then(m => m.default || m)
  }
]