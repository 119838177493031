<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'

import { useWebsiteSettings } from '@/state/websiteSettings'

await useWebsiteSettings()

const { locale } = useI18n()
dayjs.locale(locale.value)
</script>
