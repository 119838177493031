export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"key":"description","name":"description","content":"Orange Warsaw Festival to najważniejsze wydarzenie muzyczne i kulturalne Warszawy oraz idealne rozpoczęcie festiwalowego lata"},{"key":"keywords","name":"keywords","content":"festiwal, muzyka na żywo, koncerty, impreza, wydarzenie, program, aktywności, atrakcje  , muzyka, Warszawa, show, zabawa, występy, artyści, artysta, live, festival, orange, Orange, music, czerwiec"},{"key":"og:title","property":"og:title","content":"Orange Warsaw Festival"},{"key":"og:description","property":"og:description","content":"Orange Warsaw Festival to największy festiwal muzyczny w Warszawie"},{"key":"og:image","property":"og:image","content":"/og_image.jpeg"},{"name":"msapplication-TileColor","content":"#da532c"},{"name":"theme-color","content":"#ffffff"},{"name":"robots","content":"index, follow"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#5bbad5"},{"rel":"preconnect","href":"https://backend.orangewarsawfestival.pl"}],"style":[],"script":[],"noscript":[{"key":"base-image","innerHTML":"\n          <style>\n            .image {\n              opacity: 1 !important;\n            }\n          </style>\n          "}],"title":"Orange Warsaw Festiwal","titleTemplate":"%s | Orange Warsaw Festival"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'